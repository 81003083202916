::-webkit-scrollbar {
  display: none;
}

.MuiDataGrid-main.css-204u17-MuiDataGrid-main {
  width: 100% !important;
  overflow: scroll !important;
}

.MuiDataGrid-virtualScroller.css-qvtrhg-MuiDataGrid-virtualScroller {
  min-width: max-content !important;
}

.MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor.css-1iyq7zh-MuiDataGrid-columnHeaders {
  min-width: max-content !important;
}

.MuiDataGrid-cell.MuiDataGrid-cell--textLeft.MuiDataGrid-cell--editable.MuiDataGrid-withBorderColor[aria-label="Name"] {
  text-wrap: wrap !important;
  white-space: normal !important;
}

.MuiDataGrid-root
  .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)
  > .MuiDataGrid-cell {
  text-wrap: wrap !important;
  white-space: normal !important;
}

.css-k008qs{
  width: 100%;
  justify-content: space-around;
}

.MuiDataGrid-columnHeadersInner {
  min-width: max-content !important;
  width: 100% !important;
}

.MuiDataGrid-columnHeaderRow {
  width: 100% !important;
  justify-content: space-around !important;
}
.css-yrdy0g-MuiDataGrid-columnHeaderRow{
  width: 100% !important;
  justify-content: space-around !important;
}


.MuiDataGrid-columnHeaderTitleContainer {
  justify-content: space-between !important;
}

.MuiDataGrid-virtualScrollerRenderZone {
  width: 100% !important;
}
.MuiDataGrid-virtualScrollerRenderZone > div {
  width: 100% !important;
  justify-content: space-around !important;
}
.MuiDataGrid-actionsCell{
  width: 100%;
}

.MuiDataGrid-row.MuiDataGrid-row--editable {
  width: 100% !important;
  justify-content: space-around !important;
  border-bottom: 1px solid #e5e7eb !important;
  overflow: hidden !important;
}

.MuiDataGrid-cell.MuiDataGrid-withBorderColor {
  display: none !important;
}

.MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-withBorderColor {
  display: flex !important;
  width: auto !important;
  border: none !important;
  justify-content: flex-start !important;
}

.MuiDataGrid-cell--textLeft.MuiDataGrid-cell.MuiDataGrid-withBorderColor {
  display: flex !important;
  width: auto !important;
  border: none !important;
}
.css-rqymsy {
  height: 100% !important;
}
.flex-col-reverse{
  flex-direction: column-reverse !important;
}
.MuiDataGrid-footerContainer{
  border-bottom: 1px solid rgba(128, 128, 128, 0.393) !important;
}

.MuiDataGrid-cell:focus-within{
  border-color: #DDDFE8 !important;
}