::-webkit-scrollbar {
  display: none;
}

input[type="checkbox"] {
  accent-color: #DA1A42;
}

.MuiDataGrid-row:hover{
  background-color: #EEF2F7 !important;
}

.MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-visible, .MuiDataGrid-cell:active {
  outline-color: #EEF2F7 ;
}

.MuiBox-root:focus, .MuiBox-root:focus-visible, .MuiBox-root:active {
  outline-color: #EEF2F7 ;
}

button:focus, button:focus-visible, button:active {
  outline-color: #EEF2F7 ;
  outline: 2px solid #EEF2F7; /* Set the outline color */
  /* outline-offset: 2px;  */
}

input:focus, input:focus-visible, input:active {
  outline-color: #EEF2F7 ;
  outline: 2px solid #EEF2F7; /* Set the outline color */
  /* outline-offset: 2px;  */
}

select {
  background-color: white;
  border: none;
  background-color: white; /* Set background color */
  border: none; /* Remove border */
  -webkit-appearance: none; /* Remove default styling in Safari */
  -moz-appearance: none; /* Remove default styling in Firefox */
  appearance: none; /* Ensure consistency across browsers */

}

/* select option::before,
select option::-webkit-keygen-select,
select option:focus,
select option:active,
select option:checked,
select option:hover {
  color: white;
  background-color: #DA1A42 !important;
  box-shadow: 0 0 10px 100px #DA1A42 inset;
} */

.hide {
  display: none;
}

/* Initial state */
.heightTran {
  /* animation: max-height 1s ease; */
  transition: max-height 1s ease;
  overflow: hidden;
}

.heightTran.expanded {
  max-height: max-content;
}

.effect {
  transition: all 0.5s ease;
}

.fadeEffect {
  transition: opacity 0.5s ease;
}

.heightTransition {
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.5s ease, opacity 0.5s ease;
}

.heightTransition {
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.5s ease, opacity 0.5s ease;
}

/* Final state */
.heightTransition.active {
  height: auto;
  max-height: 200px;
  /* Adjust this value to your desired final height */
  opacity: 1;
  overflow-y: scroll;
}

.heightTransition.active::-webkit-scrollbar {
  display: block;
  background-color: #EEF2F7;
  width: 5px;
  border-radius: 5px;
}

.heightTransition.active::-webkit-scrollbar-thumb {
  display: block;
  background-color: #DA1A42;
  border-radius: 5px;
}

.scrollerRed::-webkit-scrollbar {
  display: block;
  background-color: #EEF2F7;
  width: 5px;
  border-radius: 5px;
}

.scrollerRed::-webkit-scrollbar-thumb {
  display: block;
  background: #DDDFE8;
  border-radius: 5px;
}

@-moz-document url-prefix() {
  /* Custom Scrollbar for .heightTransition.active in Firefox */
  .heightTransition.active {
    scrollbar-width: thin;
    scrollbar-color: #EEF2F7 #DDDFE8;
  }

  /* Custom Scrollbar for .scrollerRed in Firefox */
  .scrollerRed {
    scrollbar-width: thin;
    scrollbar-color: #DDDFE8 #DA1A42;
  }
}

/* Optional: Add transition for other properties */
.heightTransitionV2 {
  transition: max-height 0.5s ease-out, opacity 0.5s ease-out;
}

/* Optional: Add a different animation direction */
.heightTransitionV2.active {
  max-height: 0;
  opacity: 0;
}

.backDrop {
  /* filter: drop-shadow(0px 0px 1px rgba(255, 255, 255)); */
  filter: invert();
}


.notistack-SnackbarContainer,
.go4034260886 {
  /* top: 5% !important;
  right: 10% !important;
  transform: translateX(50%) !important;
  flex-direction: column !important; */
}

.MuiPaper-root.snackbar {
  background: transparent;
  box-shadow: none;

  >.container {
    border-radius: 0.75rem;
    background: #DA1A42;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 2rem;
    grid-gap: 1rem;
    color: white;

    >svg {
      color: white;
      font-size: 1.5rem;
    }

    >p {
      font-size: 18px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 0.02em;
      text-align: center;
    }

    >button {
      color: white;
      padding: 0;
    }
  }
}

.css-az8st9-MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.css-az8st9-MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: none !important;
}

.css-az8st9-MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
.css-az8st9-MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}