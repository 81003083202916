
.iconEffect {
    transition: all 0.3s ease-in-out;
  }
  
  .iconRotate {
    transform: rotate(90deg);
  }
  
  .boxesContainer {
    column-count: 3;
    column-gap: 10px;
    width: 100%;
  }

  .w-fill{
    width: -webkit-fill-available;
  }
  
  .boxes {
    min-width: 30%;
    max-width: 400px;
    margin: 0;
    display: grid;
    grid-template-rows: 1fr auto;
    margin-bottom: 10px;
  }