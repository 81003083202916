.MuiButtonBase-root.MuiMenuItem-root:hover{
  background-color: #EEF2F7 !important;
  color: black;
}

.MuiButtonBase-root.MuiMenuItem-root.Mui-selected{
  background-color: #DA1A42 !important;
  color: white;
}

.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input{
  padding-top: 0% !important;
  padding-bottom: 0% !important;
  border: 0px !important;
  outline: 0px !important;
}

.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input:focus-visible,
.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input:active,
.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input:active,
.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input:focus{
  padding-top: 0% !important;
  padding-bottom: 0% !important;
  border: 0px !important;
  outline: 0px !important;
}

.MuiOutlinedInput-notchedOutline,
.MuiOutlinedInput-notchedOutline:focus-visible,
.MuiOutlinedInput-notchedOutline:active,
.MuiOutlinedInput-notchedOutline:active,
.MuiOutlinedInput-notchedOutline:focus{
  padding-top: 0% !important;
  padding-bottom: 0% !important;
  border: 0px !important;
  outline: 0px !important;
}